import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class AppBarTitleService {
    private _title: string;

    get title(): string {
        return this.title;
    }
    set title(value: string) {
        this._title = value;
        this.titleChange.next(value);
    }

    private titleSubject = new Subject<string>();
    get titleChange() {
        return this.titleSubject;
    }
}
