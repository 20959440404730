import * as Sentry from "@sentry/angular-ivy";
import { ConfigService } from "../../lib/config/config.service";
import { APP_INITIALIZER, ErrorHandler } from "@angular/core";
import { Router } from "@angular/router";
import { EnvironmentType } from "../../lib/config/config.model";

export function initSentry() {
  const envType: EnvironmentType = ConfigService.environmentGlobal.sentry?.environment;

  Sentry.init({
    enabled: ConfigService.environmentGlobal.sentry?.enabled,
    dsn: ConfigService.environmentGlobal.sentry?.dsn,
    tunnel: `${ConfigService.environmentGlobal.sentry?.proxy}/tunnel`,
    release: ConfigService.environmentGlobal.appVersion,
    environment: envType,
    debug: ConfigService.environmentGlobal.sentry?.debug ?? false,
    tracePropagationTargets: ConfigService.environmentGlobal.sentry?.trace_propagation_targets,

    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],

    tracesSampleRate: envType === EnvironmentType.Production ? 0.1 : 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

export const sentryProviders = [
  {
    provide: ErrorHandler,
    useValue: Sentry.createErrorHandler(),
  },
  {
    provide: Sentry.TraceService,
    deps: [Router],
  },
  {
    provide: APP_INITIALIZER,
    useFactory: () => () => { },
    deps: [Sentry.TraceService],
    multi: true,
  },
]
