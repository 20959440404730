export class StringHelper {

    static phoneFormat(phone: string) {
        if (!phone)
            return null;
            
        phone = phone.replace(/\D/g, '');

        if(phone.startsWith('1'))
            phone = phone.substring(1);

        return '(' + phone.substring(0, 3) + ') ' + phone.substring(3, 6) + '-' + phone.substring(6);
    }
}
