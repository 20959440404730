import { Injectable } from "@angular/core";
import { MessageType } from "./message-type.enum";
import { MessageData } from "./message.model";
import { ToastrService } from "ngx-toastr";
import { getIndividualConfig } from "./config/message.config";

@Injectable({ providedIn: "root" })
export class MessageService {
  constructor(private toastr: ToastrService) {}

  errorDefault(logMessage?: any) {
    this.error("Something went wrong, please try again later");
    console.error(logMessage);
  }

  error(error: any, title?: string) {
    error = error.error || error;
    const message = error
      ? error.message || error.Message || error.error_description || error
      : "Ups, sth went wrong";
    this.showMessage({
      messageType: MessageType.Error,
      message,
      title,
      config: {
        toastClass: "ngx-toastr custom-toast-error",
        timeOut: 0,
        closeButton: true,
      },
    });
  }

  success(message: string, title?: string) {
    this.showMessage({
      messageType: MessageType.Success,
      message,
      title,
      config: { toastClass: "ngx-toastr custom-toast-success" },
    });
  }

  info(message: string, title?: string) {
    this.showMessage({
      messageType: MessageType.Info,
      message,
      title,
      config: { toastClass: "ngx-toastr custom-toast-info" },
    });
  }

  warning(message: string, title?: string) {
    this.showMessage({
      messageType: MessageType.Warning,
      message,
      title,
      config: {
        toastClass: "ngx-toastr custom-toast-warning",
        timeOut: 7000,
      },
    });
  }

  showMessage(data: MessageData) {
    const config = {
      ...getIndividualConfig(),
      ...data.config,
    };

    this.toastr[data.messageType](data.message, data.title, config);
  }
}
