import { BrowserModule } from "@angular/platform-browser";
import { CommonModule as NgCommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MaterialModule } from "./material/material.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppModule } from "./app/app.module";
import { RouterModule } from "@angular/router";
import { SvgsModule } from "./svgs/svgs.module";
import { ChartModule } from "./chart/chart.module";
import { DialogConfirm } from "./dialog/confirm/dialog-confirm.component";
import { DialogHtml } from "./dialog/html/dialog-html.component";
import { MaskModule } from "./mask/mask.module";
import { StatusLabelComponent } from "./status-label/status-label.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { PipesModule } from "./pipes/pipes.module";
import { FilterParamDateRangeInputComponent } from "./filter/filter-param-date-range-input/filter-param-date-range-input.component";
import { FiltersActiveListModule } from "./filter/filters-active-list/filters-active-list.module";
import { DatepickerComponent } from "./datepicker/datepicker.component";
import { DateMaskDirective } from "./datepicker/date-mask.directive";
import { SecurityInfoDialogComponent } from "./dialog/security-info-dialog/security-info-dialog.component";
import { AvatarIconComponent } from "./avatar-icon/avatar-icon.component";
import { SslInfoComponent } from "./ssl-info/ssl-info.component";
import { AutocompleteComponent } from "./autocomplete/autocomplete.component";
import { FilterParamAmountRangeInputComponent } from "./filter/filter-param-amount-range-input/filter-param-amount-range-input.component";
import { NgxMaskModule } from "ngx-mask";
import { DialogActionComponent } from "./dialog/action/dialog-action.component";

@NgModule({
  imports: [
    BrowserModule,
    RouterModule,
    NgCommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    AppModule,
    ChartModule,
    SvgsModule,
    MaskModule,
    FlexLayoutModule,
    PipesModule,
    FiltersActiveListModule,
    NgxMaskModule.forChild(),
  ],
  declarations: [
    DialogConfirm,
    DialogHtml,
    StatusLabelComponent,
    FilterParamDateRangeInputComponent,
    FilterParamAmountRangeInputComponent,
    DatepickerComponent,
    DateMaskDirective,
    SecurityInfoDialogComponent,
    AvatarIconComponent,
    SslInfoComponent,
    AutocompleteComponent,
    DialogActionComponent,
  ],
  exports: [
    BrowserModule,
    NgCommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    ChartModule,
    SvgsModule,
    AppModule,
    DialogConfirm,
    DialogHtml,
    MaskModule,
    StatusLabelComponent,
    PipesModule,
    FilterParamDateRangeInputComponent,
    FilterParamAmountRangeInputComponent,
    FiltersActiveListModule,
    DatepickerComponent,
    DateMaskDirective,
    AvatarIconComponent,
    SslInfoComponent,
    AutocompleteComponent,
    FlexLayoutModule,
    NgxMaskModule,
    DialogActionComponent,
  ],
})
export class UiModule {}
